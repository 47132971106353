<template>
  <li class="nav-item" :class="{ 'sidebar-group-active active': isActive }">
    <b-link class="nav-link" :to="{ name: item.route }">
      <feather-icon size="14" :icon="item.icon" />

      <span class="menu-title">{{ t(item.title) }}</span>
      <span v-if="item.title === 'Buchungsanfrage'"
        ><b-badge variant="primary" style="margin-left: 5px">
          {{ cartItemCount }}
        </b-badge></span
      >
    </b-link>
  </li>
</template>

<script>
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import useHorizontalNavMenuHeaderLink from './useHorizontalNavMenuHeaderLink'
import mixinHorizontalNavMenuHeaderLink from './mixinHorizontalNavMenuHeaderLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinHorizontalNavMenuHeaderLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
    cartItemCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { isActive, updateIsActive } = useHorizontalNavMenuHeaderLink(
      props.item
    )

    const { t } = useI18nUtils()
    const { canViewHorizontalNavMenuHeaderLink } = useAclUtils()

    return {
      isActive,
      updateIsActive,

      // ACL
      canViewHorizontalNavMenuHeaderLink,

      // i18n
      t,
    }
  },
}
</script>
