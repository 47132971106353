<template lang="">
  <b-container fluid>
    <b-row>
      <b-col>
        <!-- <div class="d-flex flex-column flex-sm-row pt-1"> -->
        <b-button variant="primary" @click="lagerauswahl">
          <div class="d-flex align-items-center">
            <feather-icon icon="ShoppingBagIcon" />
            <span class="ml-1">Lagerauswahl</span>
          </div>
        </b-button>

        </b-button>
        <!-- </div> -->
      </b-col>
      <b-col>
        <b-button variant="primary" @click="buchungsanfrage">
          <div class="d-flex align-items-center">
            <feather-icon icon="ShoppingCartIcon" />
            <span class="ml-1 mr-1">Buchungsanfrage</span>
          ({{ cartItemCount }})
        
          </div>
        </b-button>

      </b-col>
      <b-col>
             <b-button variant="outline" @click="docupload">
          <div class="d-flex align-items-center">
            <feather-icon icon="PaperclipIcon" />
            <span class="ml-1">Dokumentenablage</span>
          </div>
        </b-button>
          </b-col>
        
    </b-row>
  </b-container>
</template>
<script>
import { BButton, BCol, BRow, BContainer, BBadge, BLink } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BContainer,
    BBadge,
    BLink,
  },
  // props: {

  //   cartItemCount: {
  //     type: Number,
  //     default: 0,
  //   },
  // },
  computed: {
    getStorageEntries() {
      return this.$store.getters.storageEntries
    },
    cartItemCount() {
      return this.$store.getters.cartItemCount
    },
  },
  methods: {
    lagerauswahl() {
      this.$router.push({ name: 'lagermeister-shop' })
    },
    buchungsanfrage() {
      this.$router.push({ name: 'lagermeister-checkout' })
    },
    docupload() {
      this.$router.push({ name: 'lagermeister-docupload' })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
