<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <!-- <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="28" />
        </b-link>
      </li>
    </ul> -->

    <ul class="nav navbar-nav">
      <li class="nav-item">
        <actions />
      </li>
    </ul>

    <!-- Left Col -->
    <!-- bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex -->
    <!-- <b-navbar-nav class="align-items-center mr-auto">
      <actions />
    </b-navbar-nav> -->
    <!-- <b-navbar-nav class="align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <user-dropdown />
    </b-navbar-nav> -->
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BRow,
  BCol,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import UserDropdown from './UserDropdown.vue'
import Actions from './Actions.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Actions,
    // Navbar Components
    DarkToggler,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    loggedIn() {
      return this.$store.state.authentication.status.loggedIn
    },
  },
  methods: {
    logoutAction() {
      this.$store.dispatch('logoutAction')
      this.$router.push('/login')
      // this.$store.commit('RESET_CUSTOMER_ENTRIES')
    },
    showProfile() {
      this.$router.push(`/users-view/${this.user.id}`)
    },
  },
}
</script>
